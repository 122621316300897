import moment from "moment";
import { useState } from "react";
import { adminService } from "../../../api/admin";

export default function LastActivationGridRow(props) {

  const { user, paymentMethod } = props;
  const [isShow, setIsShow] = useState(false);

  const setBilling = (registrationCode) => {

    //-- token
    const token = localStorage.getItem("token");
    const params = {
      token: token,
      registrationCode: registrationCode
    };

    adminService.setBilling(params)
      .then((response) => {
        console.log(response);
        document.getElementById('btnBilling').classList.add('hidden');
        return true;
      })
      .catch((error) => console.log(error));
  }//setBilling

  //--- ---

  return (
    <>                
      <tr onClick={() => setIsShow(!isShow)} className="cursor-pointer">
        <td className="border border-slate-600 p-2">{user.name}</td>
        <td className="border border-slate-600 p-2"> {user.surname} </td>
        <td className="border border-slate-600 p-2">
          {user.email}
          <div className="text-xs font-semibold">{user.domain}</div>
        </td>
        <td className="border border-slate-600 p-2"> 
          {paymentMethod(user.paymentType)}           
          <div className="text-xs font-semibold">{user.item}</div>          
          {(user.paymentType === 'bankTransfer') && <div className="text-xs font-semibold"><a href={'http://app.labmar.it/backend/v1/upload/'+user.fileName} rel="noreferrer" target='_blank'>download ricevuta</a></div>}
          {(user?.examDate) && <div className="text-xs font-semibold text-red-900">Data Esame: {moment(user?.examDate || '').format("DD/MM/YYYY")}</div>}
          {(user.isBilled === 1) && <div className="text-xs font-semibold text-green-700">fattura emessa</div>}
        </td>
        <td className="border border-slate-600 p-2 text-left font-semibold"> 
          {(parseInt(user?.progress) > 0) ? <span className="text-red-900">{user.registrationCode}</span> : <a href={'https://app.labmar.it/backend/v1/userCertification/setUserReleasedAtCertification?registrationCode='+user.registrationCode} rel="noreferrer" target="_blank">{user.registrationCode}</a>} 
          {(user.usedBonus !== '') && <div className="text-xs font-semibold">sconto: {user.usedBonus}</div>}
          <div className="text-xs font-semibold">Prezzo: {parseFloat(user.price).toFixed(2)} {user.usedBonus !== '' && (`- Prezzo Scontato: ${parseFloat(user.priceDiscount).toFixed(2)}`)}</div>
        </td>
        <td className="border border-slate-600 p-2 text-center">{moment(user.paymentDate).format("DD/MM/YYYY HH:mm")}</td>
      </tr>
      <tr className={isShow === false ? "hidden" : ""}>
        <td colSpan={6} className="border border-slate-600 p-2 bg-white">
          <ul className="mt-1 p-0 flex flex-col lg:grid lg:grid-cols-3 lg:gap-4">
            <li className="w-full mb-4 p-3 items-start border rounded-md border-gray-300">
              <div className="font-semibold text-lg underline">Dati Personali:</div>
              <ul>
                <li><span className="font-semibold">Data:</span> {moment(user?.userDetails?.dateOfBirth || '').format("DD/MM/YYYY")}</li>
                <li><span className="font-semibold">Città:</span> {user?.userDetails?.birthCity || ''}</li>
                <li><span className="font-semibold">Provincia:</span> {user?.userDetails?.birthProvince || ''}</li>
                <li><span className="font-semibold">Codice Fiscale:</span> {user?.userDetails?.fiscalCode || ''}</li>
                <li><span className="font-semibold">Telefono:</span> {user?.userDetails?.phoneMobile || ''}</li>
              </ul>
            </li>
            <li className="w-full mb-4 p-3 items-start border rounded-md border-gray-300">
              <div className="font-semibold text-lg underline">Dati Residenza:</div>
              <ul>
                <li><span className="font-semibold">indirizzo:</span> {user?.userDetails?.residenceAddress || ''}, {user?.userDetails?.residenceHouseNumber || ''}</li>
                <li><span className="font-semibold">Città:</span> {user?.userDetails?.residenceCity || ''}</li>
                <li><span className="font-semibold">Provincia:</span> {user?.userDetails?.residenceProvince || ''}</li>
                <li><span className="font-semibold">CAP:</span> {user?.userDetails?.residenceZipCode || ''}</li>
              </ul>
            </li>
            <li className="w-full mb-4 p-3 items-start border rounded-md border-gray-300">
              <div className="font-semibold text-lg underline">Dati Fatturazione:</div>
              <ul>
                <li><span className="font-semibold">Azienda:</span> {user?.userDetails?.companyName || ''}</li>
                <li><span className="font-semibold">P.IVA:</span> {user?.userDetails?.taxID || ''}</li>
                <li><span className="font-semibold">Codice Fiscale:</span> {user?.userDetails?.fiscalCodeCompany || ''}</li>
                <li><span className="font-semibold">Indirizzo:</span> {user?.userDetails?.address || ''}, {user?.userDetails?.houseNumber || ''}</li>
                <li><span className="font-semibold">Città:</span> {user?.userDetails?.city || ''}</li>
                <li><span className="font-semibold">Provincia:</span> {user?.userDetails?.province || ''}</li>
                <li><span className="font-semibold">CAP:</span> {user?.userDetails?.zipcode || ''}</li>
                <li><span className="font-semibold">Codice Univoco:</span> {user?.userDetails?.uniqueCodeBilling || ''}</li>
                <li><span className="font-semibold">PEC:</span> {user?.userDetails?.pec || ''}</li>
                {(user.isBilled === 0) && <li id="btnBilling" className="flex justify-end">
                  <button onClick={()=>{setBilling(user.registrationCode)}} className="my-2 align-middle select-none font-bold text-center uppercase text-xs py-2 px-4 rounded-lg cursor-pointer bg-green-600 text-white">Fattura Emessa</button>
                </li>}
              </ul>
            </li>
          </ul>
        </td>
      </tr>
    </>
  );
}
