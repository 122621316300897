export const ROUTES = {
  ROOT: "/",
  LOGIN: "/login/:loginByToken?",
  REGISTER: "/register",
  COURSES: "/courses",
  COURSE: "/courses/course",
  QUIZ: "/courses/quiz",
  CERTIFICATES: "/certificates",
  CERTIFICATE: "/certificate",
  ACCOUNT: "/account",
  VERIFY: "/verify",
  VERIFYQRCODE: "/verifyQrCode",
  USERS: "/admin/users/:role?/:idParent?",
  CERTIFICATESADMIN: "/admin/certificates",
  CERTIFICATESADMIN_TUTOR: "/admin/certificates-tutor",
  COURSESADMIN: "/admin/courses",
  DOMAINS: "/admin/domains",
  COUPONS: "/admin/coupons",
  PAYMENT: "/payment",
  THANKYOUPAYMENT: "/thankYouPayment",
  WRONGPAYMENT: "/wrongPayment",
  PRICELIST: "/admin/priceList",
  COURSEONAIR: "/admin/course-onair",
  COURSEONAIR_TUTOR: "/admin/course-onair-tutor",
  EXAMPASSEDADMIN: "/admin/examspassed",
  EXAMPASSEDADMIN_TUTOR: "/admin/examspassed-tutor",
  THANKYOUPAYMENTTUTOR: "/thankYouPayment-tutor",
  WRONGPAYMENTTUTOR: "/wrongPayment-tutor",
  GATEPAYMENT: "/paypalframe/:key/:description/:price/credits",
  USERLOG: "/admin/user-log",
  USERACTIVATIONCOURSE: "/admin/user-activation-course",
  WEEKLYCERTIFICATESREPORTADMIN: "/admin/weeklycertificatesreport",
  INSPECTOR_USER_LIST: "/admin/inspector-user-list",
};
