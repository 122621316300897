import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { ROUTES } from "./routes";

import { userService } from "../api/user";

import {
  LoginView,
  RegisterView,
  CoursesView,
  CourseView,
  QuizView,
  CertificateView,
  CertificatesView,
  VerifyView,
  VerifyQrCodeView,
  UsersView,
  CertificatesAdminView,
  CoursesAdminView,
  DomainsView,
  CouponsView,
  PaymentView,
  ThankYoutPayment,
  AccountView,
  WrongPayment,
  WrongPaymentTutor,
  ThankYoutPaymentTutor,
} from "../views";

import Protected from "../protected";
import ProtectedAdmin from "../protectedAdmin";
import PriceListViiew from "../views/admin/pricelist";
import CoursesAdminViewOnAir from "../views/admin/courseUser";
import ExamsAdminView from "../views/admin/exams";
import UserLog from "../views/admin/userLog";
import WeeklyCertificatesReportView from "../views/admin/weeklycertificatesreport";
import InspectorUsersView from "../views/admin/inspectorUserCourses";
import UserActivationCourse from "../views/admin/userActivationCourse";

const AppRoutes = ({ token, setToken }) => {
  const [userToken, setUserToken] = useState(0);

  useEffect(() => {
    const session = localStorage.getItem("token");

    if (session) {
      // setUserToken(true);
      userService
        .validToken({ session })
        .then((response) => {
          if (response?.data?.payload?.length) {
            setUserToken(true);
          }
        })
        .catch((error) => {
          setUserToken(false);
        });
    } else {
      setUserToken(false);
    }
  }, [userToken]);

  if (userToken === 0) {
    return;
  }

  return (
    <Routes>
      <Route
        path={ROUTES.LOGIN}
        element={
          <LoginView
            setUserToken={setUserToken}
            token={token}
            setToken={setToken}
          />
        }
      />
      <Route path={ROUTES.REGISTER} exact element={<RegisterView />} />
      <Route path={ROUTES.VERIFY} exact element={<VerifyView />} />
      <Route path={ROUTES.VERIFYQRCODE} exact element={<VerifyQrCodeView />} />
      <Route path={ROUTES.CERTIFICATE} exact element={<CertificateView />} />
      <Route
        path="*"
        element={
          <LoginView
            setUserToken={setUserToken}
            token={token}
            setToken={setToken}
          />
        }
      />
      <Route
        path={"/"}
        exact
        element={
          <Protected userToken={userToken}>
            {localStorage.getItem("role") === "user" ? (
              <CoursesView />
            ) : (
              <AccountView />
            )}
          </Protected>
        }
      />
      <Route
        path={ROUTES.COURSES}
        exact
        element={
          <Protected userToken={userToken}>
            <CoursesView />
          </Protected>
        }
      />
      <Route
        path={ROUTES.COURSE}
        exact
        element={
          <Protected userToken={userToken}>
            <CourseView />
          </Protected>
        }
      />
      <Route
        path={ROUTES.QUIZ}
        exact
        element={
          <Protected userToken={userToken}>
            <QuizView />
          </Protected>
        }
      />
      <Route
        path={ROUTES.CERTIFICATES}
        exact
        element={
          <Protected userToken={userToken}>
            <CertificatesView />
          </Protected>
        }
      />
      <Route
        path={ROUTES.PAYMENT}
        exact
        element={
          <Protected userToken={userToken}>
            <PaymentView />
          </Protected>
        }
      />
      <Route
        path={ROUTES.THANKYOUPAYMENT}
        exact
        element={
          <Protected userToken={userToken}>
            <ThankYoutPayment />
          </Protected>
        }
      />
      <Route
        path={ROUTES.WRONGPAYMENT}
        exact
        element={
          <Protected userToken={userToken}>
            <WrongPayment />
          </Protected>
        }
      />
      <Route
        path={ROUTES.THANKYOUPAYMENTTUTOR}
        exact
        element={
          <Protected userToken={userToken}>
            <ThankYoutPaymentTutor />
          </Protected>
        }
      />
      <Route
        path={ROUTES.WRONGPAYMENTTUTOR}
        exact
        element={
          <Protected userToken={userToken}>
            <WrongPaymentTutor />
          </Protected>
        }
      />
      <Route
        path={ROUTES.ACCOUNT}
        exact
        element={
          <Protected userToken={userToken}>
            <AccountView />
          </Protected>
        }
      />

      {/* ADMIN */}

      <Route
        path={ROUTES.CERTIFICATESADMIN}
        exact
        element={
          <ProtectedAdmin userToken={userToken}>
            <CertificatesAdminView />
          </ProtectedAdmin>
        }
      />

      <Route
        path={ROUTES.EXAMPASSEDADMIN}
        exact
        element={
          <ProtectedAdmin userToken={userToken}>
            <ExamsAdminView />
          </ProtectedAdmin>
        }
      />

      <Route
        path={ROUTES.EXAMPASSEDADMIN_TUTOR}
        exact
        element={
          <ProtectedAdmin userToken={userToken}>
            <ExamsAdminView />
          </ProtectedAdmin>
        }
      />

      <Route
        path={ROUTES.WEEKLYCERTIFICATESREPORTADMIN}
        exact
        element={
          <ProtectedAdmin userToken={userToken}>
            <WeeklyCertificatesReportView />
          </ProtectedAdmin>
        }
      />

      <Route
        path={ROUTES.CERTIFICATESADMIN_TUTOR}
        exact
        element={
          <ProtectedAdmin userToken={userToken}>
            <CertificatesAdminView />
          </ProtectedAdmin>
        }
      />

      <Route
        path={ROUTES.USERS}
        exact
        element={
          <ProtectedAdmin userToken={userToken}>
            <UsersView />
          </ProtectedAdmin>
        }
      />
      <Route
        path={ROUTES.DOMAINS}
        exact
        element={
          <ProtectedAdmin userToken={userToken}>
            <DomainsView />
          </ProtectedAdmin>
        }
      />
      <Route
        path={ROUTES.COUPONS}
        exact
        element={
          <ProtectedAdmin userToken={userToken}>
            <CouponsView />
          </ProtectedAdmin>
        }
      />
      <Route
        path={ROUTES.COURSESADMIN}
        exact
        element={
          <ProtectedAdmin userToken={userToken}>
            <CoursesAdminView />
          </ProtectedAdmin>
        }
      />
      <Route
        path={ROUTES.COURSEONAIR}
        exact
        element={
          <ProtectedAdmin userToken={userToken}>
            <CoursesAdminViewOnAir />
          </ProtectedAdmin>
        }
      />
      <Route
        path={ROUTES.COURSEONAIR_TUTOR}
        exact
        element={
          <ProtectedAdmin userToken={userToken}>
            <CoursesAdminViewOnAir />
          </ProtectedAdmin>
        }
      />

      <Route
        path={ROUTES.USERLOG}
        exact
        element={
          <ProtectedAdmin userToken={userToken}>
            <UserLog />
          </ProtectedAdmin>
        }
      />

      <Route
        path={ROUTES.USERACTIVATIONCOURSE}
        exact
        element={
          <ProtectedAdmin userToken={userToken}>
            <UserActivationCourse />
          </ProtectedAdmin>
        }
      />

      {/* ADMIN TUTOR */}

      <Route
        path={ROUTES.PRICELIST}
        exact
        element={
          <ProtectedAdmin userToken={userToken}>
            <PriceListViiew />
          </ProtectedAdmin>
        }
      />

      <Route
        path={ROUTES.GATEPAYMENT}
        exact
        element={
          <ProtectedAdmin userToken={userToken}>
            <PriceListViiew />
          </ProtectedAdmin>
        }
      />

      {/* INSPECTOR */}

      <Route
        path={ROUTES.INSPECTOR_USER_LIST}
        exact
        element={
          <ProtectedAdmin userToken={userToken}>
            <InspectorUsersView />
          </ProtectedAdmin>
        }
      />
    </Routes>
  );
};

export default AppRoutes;
