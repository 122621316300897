import React, { useEffect, useRef, useState } from "react";
import useFileUpload from "react-use-file-upload";
import { adminService } from "../../api/admin";

export default function UploadTutorLogo(props) {
  const [uploadError, setUploadError] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [previewUrl, setPreviewUrl] = useState("");

  const {
    files,
    fileNames,
    //fileTypes,
    //totalSize,
    //totalSizeInBytes,
    handleDragDropEvent,
    clearAllFiles,
    //createFormData,
    setFiles,
    //removeFile,
  } = useFileUpload();

  const inputRef = useRef();

  //--- convert file in base64
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (fileNames.length > 1) {
      setUploadError(true);
      return;
    }

    //const formData = createFormData();

    try {
      const base64File = await toBase64(files[0]);

      adminService
        .uploadTutorLogo({
          token: localStorage.getItem("token"),
          idParent: props?.userId || 0,
          base64File: base64File,
        })
        .then((response) => {
          if (response.status === 200) {
            setIsFileUploaded(true);
            handleClearAll();
            props.setCurrentState(!props.currentState);
            setTimeout(() => {
              alert("File caricato con successo.");
            }, 500);
          } else {
            alert("Errore nel caricamento del file.");
          }
        })
        .catch((error) => {});
    } catch (error) {
      alert("Errore nel caricamento del file.");
      console.error("Failed to submit files.");
    }
  };

  const handleSetFiles = (e, type) => {
    if (files.length > 0) {
      setUploadError(true);
      return true;
    }

    // Controllo dell'estensione del file
    const selectedFile = e.target.files[0];
    const extension = selectedFile.name.split(".").pop().toLowerCase();
    if (extension !== "jpg") {
      setUploadError(true);
      alert("Puoi caricare file solo di tipo .jpg");
      return;
    }

    setFiles(e, type);

    const reader = new FileReader();
    const file = e.target.files[0];

    reader.onload = () => {
      setPreviewUrl(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleClearAll = () => {
    clearAllFiles();
    setPreviewUrl("");
  };

  //---

  useEffect(() => {
    if (uploadError) {
      setTimeout(() => setUploadError(false), 5500); // Remove error message after 5 seconds
    }
  }, [uploadError]);

  // Reset isFileUploaded state when files are cleared
  useEffect(() => {
    if (files.length === 0) {
      setIsFileUploaded(false);
    }
    // eslint-disable-next-line
  }, [files]);

  useEffect(() => {
    if (fileNames.length > 0) {
      fileNames[0] = props?.userId + "." + fileNames[0].split(".")[1];
    }
    // eslint-disable-next-line
  }, [fileNames]);

  //---

  return (
    <div className="text-center">
      <h1 className="select-none font-bold uppercase">Carica Logo Tutor</h1>

      <div className="form-container text-center">
        <div>
          {previewUrl && (
            <div className="preview">
              <img src={previewUrl} alt="Anteprima del file" />
            </div>
          )}

          {/*<ul>
            {fileNames.map((name) => (
              <li key={name}>
                <span>{name}</span>
              </li>
            ))}
          </ul>*/}

          {files.length > 0 && (
            <ul>
              <li className="clear-all">
                <button
                  onClick={() => handleClearAll()}
                  className="my-4 mx-auto align-middle select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 px-6 rounded-lg bg-gradient-to-tr from-red-600 to-red-400 text-white shadow-md shadow-red-500/20 hover:shadow-lg hover:shadow-red-500/40 active:opacity-[0.85] cursor-pointer">
                  Annulla
                </button>

                {uploadError && (
                  <div className="text-red-500">
                    Puoi caricare solo un logo alla volta.
                  </div>
                )}
              </li>
            </ul>
          )}
        </div>

        <div
          onDragEnter={handleDragDropEvent}
          onDragOver={handleDragDropEvent}
          onDrop={(e) => {
            handleDragDropEvent(e);
            handleSetFiles(e, "a");
          }}
          onClick={() => inputRef.current.click()}
          className="my-6 mx-2 py-6 px-4 border-2 border-dashed border-gray-300 rounded-lg hover:border-blue-500 hover:shadow-lg transition-all cursor-pointer"
          style={{ pointerEvents: isFileUploaded ? "none" : "auto" }}>
          <p>Trascina un file qui</p>
          <button>Oppure seleziona un file da caricare</button>
          <input
            ref={inputRef}
            type="file"
            multiple
            style={{ display: "none" }}
            onChange={(e) => {
              handleSetFiles(e, "a");
              inputRef.current.value = null;
            }}
            disabled={isFileUploaded}
          />
        </div>
      </div>

      <div className="submit">
        <button
          onClick={handleSubmit}
          className={`mx-auto align-middle select-none font-sans font-bold text-center uppercase transition-all text-xs py-3 px-6 rounded-lg bg-gradient-to-tr ${
            files.length === 0 || isFileUploaded
              ? "from-gray-400 to-gray-300 text-gray-600 shadow-none cursor-not-allowed"
              : "from-blue-600 to-blue-400 text-white shadow-md shadow-blue-500/20 hover:shadow-lg hover:shadow-blue-500/40 active:opacity-[0.85] cursor-pointer"
          }`}>
          {isFileUploaded ? "File caricato" : "Conferma e carica"}
        </button>
      </div>
    </div>
  );
}
