import Axios from "axios";

export const proxyBE = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 120000
});

export const proxyBEV2 = Axios.create({
  baseURL: process.env.REACT_APP_API_V2_URL,
  timeout: 120000
});