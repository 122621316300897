import { useForm } from "react-hook-form";

import { userService } from "../../api/user";

import {
  Input,
  Button,
  Checkbox,
  Dialog,
  DialogFooter,
} from "@material-tailwind/react";

function CertificateForm(props) {
  const {
    dialogCertificate,
    setDialogCertificate,
    toggleDialogCertificate,
    getCourse,
    user,
  } = props || null;

  //console.log(user)
  //version: 2, domain: "localhost:5081", name: "calogero", surname: "zito"

  const {
    register,
    handleSubmit,
    //formState,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const { name, surname, fiscalCode, birthCity, birthProvince } =
      data || null;
    const dateOfBirth =
      data?.yearBirth + "-" + data?.monthBirth + "-" + data?.dayBirth;


    //-- token
    const token = localStorage.getItem("token");
    const params = {
      token: token,
      name: user?.version === 2 ? user?.name : name,
      surname: user?.version === 2 ? user?.surname : surname,
      fiscalCode: fiscalCode.toUpperCase(),
      dateOfBirth: dateOfBirth,
      birthCity: birthCity,
      birthProvince: birthProvince,
    };

    userService
      .userCertification({ params })
      .then((response) => {
        getCourse();
        setDialogCertificate(false);
      })
      .catch((error) => {});
  };

  const getYears = () => {
    const thisYear = new Date().getFullYear();
    const yearStartFrom = 1910;
    let yearsArr = [];
    for (let i = thisYear; i >= yearStartFrom; i--) {
      yearsArr.push(i);
    }
    return yearsArr;
  };

  const days = [...Array(31).keys()].map((x) => ++x);
  const months = [...Array(12).keys()].map((x) => ++x);
  const years = getYears();

  return (
    <Dialog
      open={dialogCertificate}
      handler={toggleDialogCertificate}
      size="xxl">
      <h1 className="text-2xl font-bold text-black p-2 md:p-4">
        Inserisci i tuoi dati per l'attestato.
      </h1>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full p-2 md:p-4 overflow-y-scroll">
        <div className="w-full">
          <div className="mt-4">
            <div className="md:grid grid-cols-2 gap-4 w-full">
              <div className="mb-4 md:mb-6">
                <p className="mb-1 font-semibold">Nome</p>
                <Input
                  label="Nome"
                  type="text"
                  {...register("name", {
                    required: user?.version === 2 ? false : true,
                  })}
                  defaultValue={user?.version === 2 ? user?.name : ""}
                  disabled={user?.version === 2 && parseInt(user?.idParent) === 0}
                />
                {errors.name && (
                  <span className="text-red-900">Inserisci il tuo nome</span>
                )}
              </div>
              <div className="mb-4 md:mb-6">
                <p className="mb-1 font-semibold">Cognome</p>
                <Input
                  label="Cognome"
                  type="text"
                  {...register("surname", {
                    required: user?.version === 2 ? false : true,
                  })}
                  defaultValue={user?.version === 2 ? user?.surname : ""}
                  disabled={user?.version === 2 && parseInt(user?.idParent) === 0}
                />
                {errors.surname && (
                  <span className="text-red-900">Inserisci il tuo cognome</span>
                )}
              </div>
            </div>
            <div className="md:grid grid-cols-2 gap-4 w-full">
              <div className="mb-4 md:mb-6">
                <p className="mb-1 font-semibold">Codice fiscale</p>
                <Input
                  label="Codice Fiscale"
                  className="uppercase"
                  type="text"
                  {...register("fiscalCode", {
                    required: true,
                    minLength: {
                      value: 16,
                      message: "devi inserire un codice fiscale valido",
                    },
                    maxLength: {
                      value: 16,
                      message: "devi inserire un codice fiscale valido",
                    },
                  })}
                  defaultValue={user?.version === 2 ? user?.fiscalCode : ""}
                />
                {errors.fiscalCode && (
                  <span className="text-red-900">
                    Inserisci il tuo codice fiscale
                  </span>
                )}
              </div>
              <div>
                <div className="mb-4 md:mb-6">
                  <p className="mb-1 font-semibold">Data di nascita</p>
                  <div className="flex flex-wrap gap-2">
                    <div>
                      <select {...register("dayBirth", { required: true })}>
                        {days?.map((item, index) => (
                          <option value={`${item}`} key={index}>
                            {item}
                          </option>
                        ))}
                      </select>

                      {errors.dayBirth && (
                        <span className="text-red-900">
                          Inserisci il tuo giorno di nascita
                        </span>
                      )}
                    </div>
                    <div>
                      <select
                        label="Mese"
                        {...register("monthBirth", { required: true })}>
                        {months?.map((item, index) => (
                          <option value={`${item}`} key={index}>
                            {item}
                          </option>
                        ))}
                      </select>
                      {errors.monthBirth && (
                        <span className="text-red-900">
                          Inserisci il tuo mese di nascita
                        </span>
                      )}
                    </div>
                    <div>
                      <select
                        label="Anno"
                        {...register("yearBirth", { required: true })}>
                        {years?.map((item, index) => (
                          <option value={`${item}`} key={index}>
                            {item}
                          </option>
                        ))}
                      </select>
                      {errors.yearBirth && (
                        <span className="text-red-900">
                          Inserisci il tuo anno di nascita
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <p className="mb-1 font-semibold">Luogo di nascita</p>
            </div>
            <div className="md:grid grid-cols-2 gap-4 w-full">
              <div className="md:grid grid-cols-2 gap-4 w-full">
                <div className="mb-4 md:mb-6">
                  <Input
                    label="Comune"
                    type="text"
                    {...register("birthCity", { required: true })}
                    defaultValue={user?.version === 2 ? user?.birthCity : ""}
                  />
                  {errors.birthCity && (
                    <span className="text-red-900">
                      Inserisci la tua città di nascita
                    </span>
                  )}
                </div>
                <div>
                  <div className="mb-4 md:mb-6">
                    <Input
                      label="Provincia"
                      type="text"
                      {...register("birthProvince", {
                        required: true,
                        maxLength: 2,
                      })}
                      defaultValue={
                        user?.version === 2 ? user?.birthProvince : ""
                      }
                    />
                    {errors.birthProvince && (
                      <span className="text-red-900">
                        Insersci la tua provincia di nascita, solo due lettere
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap items-center">
              <Checkbox
                color="green"
                {...register("accepted", { required: true })}
              />
              <p>
                Confermo di aver inserito dati corretti e veritieri sulla mia
                persona come richiesto dalla normativa vigente e come indicato
                nei termini di servizio.
                {errors.accepted && (
                  <p className="text-red-900">Conferma per continuare</p>
                )}
              </p>
            </div>
          </div>
        </div>

        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={() => toggleDialogCertificate()}
            className="mr-1">
            <span>Annulla</span>
          </Button>
          <Button variant="gradient" color="green" type="submit">
            <span>Conferma</span>
          </Button>
        </DialogFooter>
      </form>
    </Dialog>
  );
}

export default CertificateForm;
