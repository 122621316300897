import React, { useState, useEffect, useRef } from "react";
import { userService } from "../../api/user";
import Layout from "../../components/layout";

import { formatDate } from "../../utils/date";
import { Alert, Button } from "@material-tailwind/react";
import { adminService } from "../../api/admin";
import moment from "moment";

export default function AccountView() {
  const token = localStorage.getItem("token");
  const [user, setUser] = useState();
  const [appConfig, setAppConfig] = useState();
  const [isSuccess, setIsSuccess] = useState(null);

  const defaultIVA = useRef();
  const protocolloAA = useRef();

  const getUser = () => {
    userService
      .getUser({ token })
      .then((response) => {
        setUser(response?.data?.payload[0]);
      })
      .catch((error) => {});
  };

  /** */
  const getAppConfig = () => {
    adminService
      .getAppConfig({ token })
      .then((response) => {
        setAppConfig(response?.data?.payload);
      })
      .catch((error) => {});
  }; //getAppConfig

  /** */
  const toggleDeadAdm = () => {
    let token = localStorage.getItem("token");
    const params = {
      token,
      id: appConfig?.data[0]?.id,
      deadAdm: appConfig?.data[0]?.deadAdm === 0 ? 1 : 0,
    };

    adminService
      .setAppConfig(params)
      .then((response) => {
        getAppConfig();
        alert("Dato Salvato");
      })
      .catch((error) => console.log(error));
  }; //toggleDeadAdm

  /** */
  const toggleVideoPath = (e) => {
    let token = localStorage.getItem("token");
    const params = {
      token,
      id: appConfig?.data[0]?.id,
    };

    if (e.target.id === "videoPath") {
      params["videoPath"] =
        appConfig?.data[0]?.videoPath === "long" ? "short" : "long";
    }

    if (e.target.id === "videoTutorPath") {
      params["videoTutorPath"] =
        appConfig?.data[0]?.videoTutorPath === "long" ? "short" : "long";
    }

    adminService
      .setAppConfig(params)
      .then((response) => {
        getAppConfig();
        alert("Dato Salvato");
      })
      .catch((error) => console.log(error));
  }; //setVideoPath

  /** */
  const handleIVAAdm = (e) => {
    let token = localStorage.getItem("token");
    const params = {
      token,
      id: appConfig?.data[0]?.id,
      iva: document.getElementById("iva")
        ? document.getElementById("iva").value
        : 0,
    };

    adminService
      .setAppConfig(params)
      .then((response) => {
        getAppConfig();
        alert("Dato Salvato");
      })
      .catch((error) => console.log(error));
  }; //handleIVAAdm

  /** */
  const handleProtocolloAdm = (e) => {
    let token = localStorage.getItem("token");
    const params = {
      token,
      id: appConfig?.data[0]?.id,
      protocolloAA: document.getElementById("protocolloAA")
        ? document.getElementById("protocolloAA").value
        : 0,
    };

    adminService
      .setAppConfig(params)
      .then((response) => {
        getAppConfig();
        alert("Dato Salvato");
      })
      .catch((error) => console.log(error));
  }; //handleProtocolloAdm

  /** */
  const handleNewCourse = (e) => {
    let token = localStorage.getItem("token");
    const params = {
      token,
    };

    adminService
      .startNewCourse(params)
      .then((response) => {
        if (response.data.payload.status === "success") {
          setIsSuccess(true);
        } else {
          setIsSuccess(false);
        }

        setTimeout(() => {
          setIsSuccess(null);
        }, 3000);
      })
      .catch((error) => {
        console.log(error);
        setIsSuccess(false);
        setTimeout(() => {
          setIsSuccess(null);
        }, 3000);

        //alert("Errore: " + error);
      });
  }; //handleNewCourse

  /** */
  const handleDeleteAccount = (type) => {
    let token = localStorage.getItem("token");
    const params = {
      token,
      type
    };

    adminService
      .deleteAccountMonthsOld(params)
      .then((response) => {
        if (response.data.payload.status === "success") {
          setIsSuccess(true);
        } else {
          setIsSuccess(false);
        }

        setTimeout(() => {
          setIsSuccess(null);
        }, 3000);
      })
      .catch((error) => {
        console.log(error);
        setIsSuccess(false);
        setTimeout(() => {
          setIsSuccess(null);
        }, 3000);

        //alert("Errore: " + error);
      });
  }; //handleDeleteAccount

  //--- ---

  useEffect(() => {
    getUser();
    getAppConfig();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    /** */
    if (
      typeof appConfig?.data[0]?.iva !== "undefined" &&
      typeof defaultIVA?.current !== "undefined"
    ) {
      defaultIVA.current.value = parseFloat(appConfig?.data[0]?.iva || 0);
    }

    /** */
    if (
      typeof appConfig?.data[0]?.protocolloAA !== "undefined" &&
      typeof protocolloAA?.current !== "undefined"
    ) {
      protocolloAA.current.value =
        moment(appConfig?.data[0]?.protocolloAA).format("DD/MM/YYYY") || "";
    }
  }, [appConfig, defaultIVA, protocolloAA, user]);

  if (!user) {
    return null;
  }

  return (
    <Layout>
      {isSuccess === true && (
        <Alert color="green" className="text-center font-bold my-4">
          Richiesta eseguita con successo.
        </Alert>
      )}

      {isSuccess === false && (
        <Alert color="red" className="text-center font-bold my-4">
          Si è verificato un errore.
        </Alert>
      )}

      <div className="container mx-auto">
        <div className="p-4 bg-white rounded-xl">
          <p className="text-xl font-bold mb-4">I tuoi dati</p>
          {user?.fiscalCode && (
            <>
              <p className="font-bold">
                {user?.name} {user?.surname}
              </p>
              <p>
                Nato a {user?.birthCity} il {formatDate(user?.dateOfBirth)}
              </p>
              <p>C.F. {user?.fiscalCode}</p>
            </>
          )}
          {user?.taxId && (
            <>
              <p className="font-bold">{user?.companyName}</p>
              <p>p.iva {user?.taxId}</p>
            </>
          )}

          {parseInt(user?.idParent || 0) === 0 && (
            <>
              <p className="mt-4">Indirizzo</p>
              <p>
                {user?.address} {user?.houseNumber}, {user?.residenceCity} (
                {user?.residenceProvince})
              </p>
            </>)
          }

          <p>email: {user?.email}</p>
          <p>telefono: {user?.phoneMobile}</p>

          {user?.role === "admin" ? (
            <>
              <div className="font-bold bg-gray-200 mt-8 py-2 px-4">
                Funzioni di Gestione della pattaforma:
              </div>
              <ul className="mt-1 p-0 flex flex-col lg:grid lg:grid-cols-3 lg:gap-4 haccpLineVertical">
                <li className="w-full mb-4 p-3 items-start">
                  <Button
                    variant="gradient"
                    color={appConfig?.data[0]?.deadAdm === 0 ? "blue" : "red"}
                    onClick={() => toggleDeadAdm()}
                  >
                    {appConfig?.data[0]?.deadAdm === 0 ? (
                      <span>disattiva nuovi account / corsi</span>
                    ) : (
                      <span>attiva nuovi account / corsi</span>
                    )}
                  </Button>
                  <div className="text-xs mt-6">
                    funzione da utilizzare consapevolente. Questa funzione
                    blocca tutte le nuove iscrizioni.
                  </div>
                </li>
                <li className="w-full mb-4 p-3 items-start">
                  <div className="flex flex-wrap">
                    <div className="inline-block mx-3">Video Brevi</div>

                    <label className="relative inline-flex items-center cursor-pointer">
                      <input
                        id="videoPath"
                        type="checkbox"
                        value=""
                        className="sr-only peer"
                        checked={
                          appConfig?.data[0]?.videoPath === "long"
                            ? "checked"
                            : ""
                        }
                        onChange={(e) => {
                          toggleVideoPath(e);
                        }}
                      />
                      <div className="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>

                    <div className="inline-block mx-3">Video Lunghi (siti)</div>
                  </div>

                  <div className="flex flex-wrap mt-4">
                    <div className="inline-block mx-3">Video Brevi</div>

                    <label className="relative inline-flex items-center cursor-pointer">
                      <input
                        id="videoTutorPath"
                        type="checkbox"
                        value=""
                        className="sr-only peer"
                        checked={
                          appConfig?.data[0]?.videoTutorPath === "long"
                            ? "checked"
                            : ""
                        }
                        onChange={(e) => {
                          toggleVideoPath(e);
                        }}
                      />
                      <div className="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>

                    <div className="inline-block mx-3">
                      Video Lunghi (tutor)
                    </div>
                  </div>
                </li>
                <li className="w-full mb-4 p-3 items-start">
                  <label>IVA: </label>
                  <div className="flex flex-nowrap gap-1.5 items-center">
                    <input
                      id="iva"
                      name="iva"
                      type="text"
                      placeholder="IVA in %"
                      ref={defaultIVA}
                      className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                    <Button
                      variant="gradient"
                      color={"blue"}
                      onClick={() => handleIVAAdm()}
                    >
                      SALVA
                    </Button>
                  </div>
                  <div className="text-xs mt-6">
                    puoi gestire il valore dell'IVA in percentuale da utilizzare
                    sulla piattaforma. Se vuoi eliminare il calcolo dell'IVA
                    aggiorna il valore a 0 (zero). NB: non mettere il simbolo %
                  </div>
                </li>
                <li className="w-full mb-4 p-3 items-start">
                  <label className="">Data protocollo annuale: </label>
                  <div className="flex flex-nowrap gap-1.5 items-center">
                    <input
                      id="protocolloAA"
                      name="protocolloAA"
                      type="text"
                      placeholder="numero di procollo annuale"
                      ref={protocolloAA}
                      className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                    <Button
                      variant="gradient"
                      color={"blue"}
                      onClick={() => handleProtocolloAdm()}
                    >
                      SALVA
                    </Button>
                  </div>
                  <div className="text-xs mt-6"></div>
                </li>
                <li className="w-full mb-4 p-3 items-start">
                  <div className="text-md">
                    Funzione per far ripartire nuovi corsi periodici come
                    richiesto dalla normativa in vigore.
                  </div>
                  <Button
                    variant="gradient"
                    color={"blue"}
                    className="my-2"
                    onClick={() => handleNewCourse()}
                  >
                    AVVIA NUOVO CORSO
                  </Button>
                  <div className="text-xs text-red-900">
                    Attenzione tutti i corsi attivi verranno chiusi. Saranno
                    riaperti dai corsisti con la nuova data di protocollo.
                    Questa funzione è da utilizzare con attenzione.
                  </div>
                </li>
                <li className="w-full mb-4 p-3 items-start">
                  <div className="text-md">
                    Funzione per cancellare tutti gli account che non hanno
                    ottenuto mai un certificato e che non hanno mai avviato un
                    corso prima dei 3 mesi precedenti.
                  </div>
                  <Button
                    variant="gradient"
                    color={"blue"}
                    className="my-2"
                    onClick={() => handleDeleteAccount('3old')}
                  >
                    ELIMINA ACCOUNTS
                  </Button>
                  <div className="text-xs text-red-900">
                    Attenzione tutti gli account e tutti i dati saranno
                    eliminati definitivamente.
                  </div>
                </li>
                <li className="w-full mb-4 p-3 items-start">
                  <div className="text-md">
                    Funzione per cancellare tutti gli account che hanno avviato un
                    corso e che non hanno ottenuto mai un certificatoprima prima dei 5 mesi precedenti.
                  </div>
                  <Button
                    variant="gradient"
                    color={"blue"}
                    className="my-2"
                    onClick={() => handleDeleteAccount('5old')}
                  >
                    ELIMINA ACCOUNTS
                  </Button>
                  <div className="text-xs text-red-900">
                    Attenzione tutti gli account e tutti i dati saranno
                    eliminati definitivamente.
                  </div>
                </li>
              </ul>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </Layout>
  );
}
