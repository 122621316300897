import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Alert,
} from "@material-tailwind/react";

function CourseCard(props) {
  // props
  const { code, title, buttonLabel, isActiveCourse, userVersion } = props || {};

  return (
    <>
      <Card className="mt-20 md:mt-0 cursor-pointer">
        <CardHeader
          className={`relative ${userVersion < 2 ? "h-56" : "h-96"} }`}>
          <img
            src={"/images/dashboard/courses/" + code + ".jpeg"}
            alt="immagine"
            className="w-full"
          />
        </CardHeader>
        <CardBody>
          {userVersion < 2 ? (
            <Typography variant="h5" className="mb-2">
              {" "}
              {title}{" "}
            </Typography>
          ) : (
            <Typography variant="h4" className="text-center">
              {" "}
              {title}{" "}
            </Typography>
          )}
        </CardBody>
        <CardFooter divider className="items-center py-3">
          <Button
            type="submit"
            className={`${
              isActiveCourse ? "bg-green-600" : "bg-indigo-600"
            } m-0 mx-auto group relative flex justify-center rounded-md border border-transparent py-4 px-10 text-md font-bold text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}>
            {buttonLabel}
          </Button>

          <Alert className="mt-7 py-3 text-center text-xl font-medium bg-indigo-500">
            ATTENZIONE: In forza al Decreto Assessoriale della Regione Sicilia n°698 del 3 Agosto 2022 le lezioni in corso nei
            giorni 1 / 11 e 21 di ogni mese saranno AZZERATE
            automaticamente. Si esortano i corsisti a completare il percorso
            formativo entro i giorni indicati. Il Corso potrà essere ripetuto senza alcun costo aggiuntivo.
          </Alert>
        </CardFooter>
      </Card>
    </>
  );
}

export default CourseCard;
