import { useEffect, useRef, useState } from "react";
import Layout from "../../../components/layout";

import styles from "./style.module.scss";
import { adminService } from "../../../api/admin";
import LastActivationGridRow from "./lastActivationGridRow";

export default function UserActivationCourse() {
  let token = localStorage.getItem("token");
  let numberPerPage = 50;

  const defaultSearch = useRef();

  const [lastActivations, setLastActivations] = useState([]);
  const [pagination, setPagination] = useState(0);
  const [pages, setPages] = useState(null);
  const [loader, setLoader] = useState(true);

  //--- ---

  const loadLastActivations = (defaultSearch = "") => {
    let params = {
      token: token,
      page: pagination,
      limit: numberPerPage,
      defaultSearch: defaultSearch,
    };

    setLoader(true);

    adminService
      .getLastActivations(params)
      .then((response) => {
        setLastActivations(response?.data?.payload?.data);
        setLoader(false);
        if (response?.data?.payload?.count) {
          setPages(Math.ceil(response?.data?.payload?.count / numberPerPage));
        }
      })
      .catch((error) => {
        setLastActivations([]);
        setPages(null);
      });
  }; //loadLastActivations

  const goToSearch = () => {
    loadLastActivations(defaultSearch.current.value);
    defaultSearch.current.value = "";
  }; //goToSearch

  const nextPage = () => {
    setPagination(pagination + 1);
  };

  const prevPage = () => {
    setPagination(pagination - 1);
  };

  const goToPage = (item) => {
    setPagination(item - 1);
  };

  let arr = [];

  const getPagination = () => {
    if (pages) {
      for (let i = 1; i <= pages; i++) {
        arr.push(i);
      }
      return arr;
    }
  };

  const paymentMethod = (paymentType) => {
    switch (paymentType) {
      case "paypal":
        return "Paypal";
      case "stripe":
        return "Stripe";
      case "bankTransfer":
        return "Bonifico";
      default:
        return "Nessun metodo di pagamento";
    }
  }; //paymentMethod

  useEffect(() => {
    loadLastActivations(defaultSearch.current.value);
    // eslint-disable-next-line
  }, []); //useEffect

  useEffect(() => {
    loadLastActivations(defaultSearch.current.value);
    // eslint-disable-next-line
  }, [pagination]);

  //--- ---

  return (
    <>
      <Layout>
        <div className="mx-auto">
          <div>
            <label
              htmlFor="default-search"
              className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
            >
              Search
            </label>

            <div className="relative mb-4 flex gap-2">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  aria-hidden="true"
                  className="w-5 h-5 text-gray-500 dark:text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  ></path>
                </svg>
              </div>
              <input
                type="search"
                id="default-search"
                ref={defaultSearch}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    goToSearch();
                  }
                }}
                className={`${
                  localStorage.getItem("role") === "tutor" ? "w-2/3" : "w-2/3"
                } p-4 pl-10 text-md text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                placeholder="cognome / email / codice fiscale"
              />

              <button
                type="submit"
                onClick={(e) => {
                  goToSearch();
                }}
                className="w-1/3 p-4 text-md text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 uppercase"
              >
                cerca
              </button>
            </div>
          </div>

          {loader === false && (
            <table className="w-full border-collapse border border-slate-500 text-left">
              <thead>
                <tr>
                  <th className="border border-slate-600 p-2 text-center">
                    Nome
                  </th>
                  <th className="border border-slate-600 p-2 text-center">
                    Cognome
                  </th>
                  <th className="border border-slate-600 p-2 text-center">
                    Email
                  </th>
                  <th className="border border-slate-600 p-2 text-center">
                    Metodo
                  </th>
                  <th className="border border-slate-600 p-2 text-center">
                    Codice Registrazione
                  </th>
                  <th className="border border-slate-600 p-2 text-center">
                    Attivazione Corso
                  </th>
                </tr>
              </thead>

                {lastActivations.map((user, idx) => (
                  <LastActivationGridRow
                    key={idx}
                    user={user}
                    paymentMethod={paymentMethod}
                  />
                ))}
            </table>
          )}

          {loader === true && (
            <>
              <div class="text-center">
                <div role="status">
                  <svg
                    aria-hidden="true"
                    class="inline w-12 h-12 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </>
          )}

          <div className={`${styles.pagination} flex flex-wrap justify-center`}>
            {pagination > 0 && (
              <div className="cursor-pointer" onClick={() => prevPage()}>
                Indietro
              </div>
            )}
            {getPagination()?.map((item, index) => (
              <div
                className={`${styles.pageNumber} ${
                  pagination === index ? styles.pageActive : ""
                }`}
                onClick={() => goToPage(item)}
                key={index}
              >
                {item}
              </div>
            ))}
            {pagination < pages && (
              <div className="cursor-pointer" onClick={() => nextPage()}>
                Avanti
              </div>
            )}
          </div>
        </div>
      </Layout>
    </>
  );
}
