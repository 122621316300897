import { useState, useEffect, useCallback } from "react";

import { useLocation, useNavigate } from "react-router-dom";

// import { CheckLogin } from "../../../utils/checkLogin";

//--- api
import { courseService } from "../../../api/course";
import { userService } from "../../../api/user";

//-- utilities
import { getDomain } from "../../../utils/domain";
import { courseUtils } from "../../../utils/course";
import { formatDate } from "../../../utils/date";

//-- components
import Layout from "../../../components/layout";
import CardLesson from "../../../components/cardLesson";
import CertificateForm from "../../../components/certficateForm";
import EditCertificateForm from "../../../components/editCertificateForm";

//-- theme components
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";

//-- icons
import { IconXCircle, IconAward } from "../../../icons";

//-- videoPlayer
import VideoPlayer from "../../../components/videoPlayer";
import { adminService } from "../../../api/admin";
import { getCurrentPathLessons } from "../../../utils/utils";

export default function CourseView() {
  //-- get course code from location params
  const location = useLocation();
  const courseCode = location?.state?.course || null;

  //-- state
  const [course, setCourse] = useState("");
  const [userCourse, setUserCourse] = useState("");
  const [registrationCode, setRegistrationCode] = useState(null);
  const [currentLesson, setCurrentLesson] = useState({});
  const [currentModule, setCurrentModule] = useState({});
  const [timeToExpiration, setTimeToExpiration] = useState();
  const [isModalLessonOpen, setIsModalLessonOpen] = useState(false);
  const [isModalAlertOpen, setIsModalAlertOpen] = useState(false);
  const [, /*dataModal*/ setDataModal] = useState();
  const [, /*lessonEnded*/ setLessonEnded] = useState(false);
  const [isModalQuizOpen, setIsModalQuizOpen] = useState(false);
  const [isModalFinalQuizOpen, setIsModalFinalQuizOpen] = useState(false);
  const [quiz, setQuiz] = useState({});
  const [dialogCertificate, setDialogCertificate] = useState(false);
  const [dialogEditCertificate, setDialogEditCertificate] = useState(false);
  const [user, setUser] = useState();
  const [videoPath, setVideoPath] = useState("short");
  const [videoTutorPath, setVideoTutorPath] = useState("short");

  console.log(timeToExpiration);

  //---
  const [priceDomain, setPriceDomain] = useState(0);
  const [priceDiscountDomain, setPriceDiscountDomain] = useState(0);
  const [appConfig, setAppConfig] = useState();

  //-- token
  const token = localStorage.getItem("token");
  // let isToken = CheckLogin(token);
  //-- domain
  const domain = getDomain();

  let navigate = useNavigate();

  let modalLesson = "";

  //-- get course from endpoint
  const getCourse = useCallback(() => {
    //-- params
    const params = {
      token,
      courseCode,
      // domain,
    };

    courseService
      .course({ params })
      .then((response) => {
        //-- set state
        setCourse(response?.data?.payload);
      })
      .catch((error) => {
        navigate("/courses");
      });
    // eslint-disable-next-line
  }, [course]);

  //-- get userCourse from endpoint
  const getUserCourse = () => {
    const params = {
      token,
      courseCode,
      domain,
    };
    courseService
      .getCourse({ params })
      .then((response) => {
        if (response?.data?.payload?.elapsedTime > 10) {
          navigate("/courses");
        }
        setUserCourse(response?.data?.payload);
        if (response?.data?.payload) {
          setRegistrationCode(response?.data?.payload?.registrationCode);
          let hours = courseUtils.time(
            response?.data?.payload?.startedAt,
            course?.maxTime
          );
          setTimeToExpiration(hours);
        }
      })
      .catch((error) => {
        navigate("/courses");
      });
  };

  const getUser = () => {
    userService
      .getUser({ token })
      .then((response) => {
        setUser(response?.data?.payload[0]);
      })
      .catch((error) => {});
  };

  /** */
  const getAppConfig = () => {
    const token = localStorage.getItem("token");
    adminService
      .getAppConfig({ token })
      .then((response) => {
        setVideoPath(response?.data?.payload.data[0]?.videoPath);
        setVideoTutorPath(response?.data?.payload.data[0]?.videoTutorPath);
        setAppConfig(response?.data?.payload);
      })
      .catch((error) => {});
  }; //getAppConfig

  /** */
  const getPriceByUserDomain = () => {
    const params = {
      token,
      courseCode: course.code,
    };

    courseService
      .getPriceByDomain({ params })
      .then((response) => {
        setPriceDomain(
          response?.data?.payload?.price > 0
            ? response?.data?.payload?.price
            : course.price
        );
        setPriceDiscountDomain(
          response?.data?.payload?.priceDiscount > 0
            ? response?.data?.payload?.priceDiscount
            : course.priceDiscount
        );
      })
      .catch((error) => {});
  }; //getPriceByUserDomain

  //-- course method hook
  useEffect(() => {
    getCourse();
    getUser();
    // eslint-disable-next-line
  }, [setCourse, setUser]);

  //-- course method hook
  useEffect(() => {
    getUserCourse();
    // eslint-disable-next-line
  }, [course]);

  //-- update video path
  useEffect(() => {
    getAppConfig();
    getPriceByUserDomain();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // eslint-disable-next-line
  }, [videoPath, videoTutorPath]);

  useEffect(() => {
    // eslint-disable-next-line
  }, [priceDiscountDomain, priceDomain]);

  const handleLesson = (e, lesson, module) => {
    e.preventDefault();
    setCurrentModule(module);
    // aggiorno state currentLesson
    setCurrentLesson(lesson);

    // apro la modale del video
    modalLesson = "modal-lesson";
    toggleModalLesson();
  };

  function toggleModalLesson() {
    if (!isModalLessonOpen) {
      setIsModalLessonOpen(true);
      setDataModal(modalLesson);
    } else {
      toggleModalAlert();
      // setIsModalLessonOpen(false);
    }
  }

  function toggleModalAlert() {
    // if (isModalLessonOpen && !lessonEnded) {
    if (isModalLessonOpen) {
      setIsModalAlertOpen(true);
    }
  }

  function handleCloseAlertModal() {
    setIsModalAlertOpen(false);
    setIsModalLessonOpen(true);
    // setIsModalLessonOpen(false);
  }

  function handleConfirmStopLesson() {
    setIsModalLessonOpen(false);
    setIsModalAlertOpen(false);
  }

  function handleLessonEnded() {
    setLessonEnded(true);
    setIsModalLessonOpen(false);
    const params = {
      // domain,
      token,
      courseId: course?.id,
      moduleId: currentModule?.id,
      lessonId: currentLesson?.id,
      registrationCode: registrationCode,
    };

    // courseService.setTrakingLessonDone({ params });
    courseService
      .setTrakingLessonDone({ params })
      .then((response) => {
        getCourse();
      })
      .catch((error) => {});
  }

  // --- quiz ready
  const toggleQuizReady = (lessons) => {
    if (lessons) {
      if (lessons.filter((e) => e.isDone === false).length > 0) {
        return false;
      }
      return true;
    }
  };

  //-- quiz open modal to confirm
  const toggleModalQuiz = (quiz) => {
    setIsModalQuizOpen(!isModalQuizOpen);
    setQuiz(quiz);
  };

  //-- quiz open modal to confirm
  const toggleModalFinalQuiz = (quiz) => {
    setIsModalFinalQuizOpen(!isModalQuizOpen);
    setQuiz(quiz);
  };

  const toggleDialogCertificate = () => {
    setDialogCertificate(!dialogCertificate);
  };

  const toggleDialogEditCertificate = () => {
    setDialogEditCertificate(!dialogEditCertificate);
    navigate("/courses/course", {
      state: {
        course: courseCode,
      },
    });
  };

  if (!course || !userCourse) {
    return;
  }

  if (!courseCode) {
    navigate("/courses");
  }

  const getCourseTime = () => {
    const startCourseDate = userCourse?.startedAt;
    //const today = new Date();
    let hours = courseUtils.hours(startCourseDate);

    if (hours < course?.minTime) {
      return false;
    } else {
      return true;
    }
  };

  const isFinalQuizRead = () => {
    if (
      course?.modules.filter((e) => e.quiz.outcome === 0).length > 0 ||
      !getCourseTime()
    ) {
      return false;
    } else {
      return true;
    }
  };

  const toggleIsUserCertificateVisible = () => {
    if (course?.modules?.filter((e) => e.quiz.outcome === 0).length > 0) {
      // console.log("non visibile");
      return false;
    } else {
      // console.log("visibile");
      return true;
    }
  };

  //--- ---

  return (
    <>
      <Layout>
        <div className="container mx-auto">
          <div className="text-2xl font-bold">{course?.title}</div>
        </div>
        <div className="mt-4">
          <div className="px-2 md:px-4 bg-blue-600 text-white p-2 md:p-4 rounded-lg">
            <p className="text-xl font-semibold">Ottieni l'attestato</p>

            <p className="mt-4">
            ATTENZIONE: In forza al Decreto Assessoriale della Regione Sicilia n°698 del 3 Agosto 2022 le lezioni in corso nei
            giorni 1 / 11 e 21 di ogni mese saranno AZZERATE
            automaticamente. Si esortano i corsisti a completare il percorso
            formativo entro i giorni indicati.
            </p>

            {/*<p className="mt-4">
              Completa il corso entro {course?.maxTime / 24} giorni (
              <span className="text-xl">{course?.maxTime} ore</span>) per
              ottenere l'attestato.
            </p>
            <p className="mt-4">
              Hai ancora a disposizione{" "}
              <b>{timeToExpiration && timeToExpiration} ore</b> per completare i
              moduli del corso, sostenere l'esame e riscattare l'attestato.
            </p>*/}

            <p className="mt-4">
              Il tempo minimo di studio è {course?.minTime} ore
            </p>
            <p className="mt-2">
              Hai iniziato il corso il: {formatDate(userCourse?.startedAt)} alle{" "}
              {courseUtils.hour(userCourse?.startedAt)}
            </p>
          </div>
        </div>
        {/* --- course modules */}
        <div className="mt-10 rounded-3xl">
          <p className="text-2xl">Moduli formativi</p>

          {course &&
            course?.modules?.map((item, index) => (
              <div key={index} className={`mt-4 bg-white p-4`}>
                <p className="text-lg font-semibold flex flex-row items-center">
                  {item?.title}
                </p>
                <hr />
                <div className="panel mt-8">
                  {item?.lessons &&
                    item?.lessons?.map((lesson, index) => (
                      <div
                        className="mb-4"
                        key={index}
                        onClick={(e) => handleLesson(e, lesson, item)}>
                        <CardLesson data={lesson} />
                      </div>
                    ))}
                  <div>
                    {item?.quiz?.outcome ? (
                      <div className="text-green-600 flex flex-wrap">
                        <IconAward />
                        <span className="font-bold">
                          Quiz intermedio superato
                        </span>
                      </div>
                    ) : toggleQuizReady(item?.lessons) ? (
                      <div
                        className="text-red-600 flex flex-wrap cursor-pointer"
                        onClick={() => toggleModalQuiz(item?.quiz)}>
                        <IconAward />
                        <span className="font-bold text-red-600">
                          Effettua il quiz intermedio
                        </span>
                      </div>
                    ) : (
                      <div className="flex flex-wrap">
                        <IconAward />
                        <span className="font-bold text-red-600">
                          Segui tutte le lezioni del modulo per effettuare il
                          quiz
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>
        {/* --- end course modules */}

        {/* certicate */}
        {course?.userCertification.length === 0 &&
          toggleIsUserCertificateVisible() && (
            <div className="mt-10 rounded-3xl">
              <p className="text-2xl">Certificato</p>
              <div className="mt-4 bg-white p-4">
                <p className="font-bold">
                  Inserisci i tuoi dati per ottenere il certificato del corso:
                </p>
                <div className="mt-4">
                  {/* <Button onClick={() => toggleDialogEditCertificate()}> */}
                  <Button onClick={() => toggleDialogCertificate()}>
                    Inserisci Dati
                  </Button>
                </div>
              </div>
            </div>
          )}

        {course?.userCertification.length !== 0 && (
          <div className="mt-10 rounded-3xl">
            <p className="text-2xl">Certificato</p>
            <div className="mt-4 bg-white p-4">
              <p className="font-bold">
                Esame e attestato saranno intestati a:
              </p>
              <div className="mt-4">
                <p className="font-bold">
                  {course?.userCertification?.name}{" "}
                  {course?.userCertification?.surname}
                </p>
                <p>
                  Nato il {formatDate(course?.userCertification?.dateOfBirth)} a{" "}
                  {course?.userCertification?.birthCity} (
                  <span className="uppercase">
                    {course?.userCertification?.birthProvince})
                  </span>
                </p>
                <p className="uppercase">
                  C.F. {course?.userCertification?.fiscalCode}
                </p>
              </div>
              <div className="mt-4">
                <Button onClick={() => toggleDialogEditCertificate()}>
                  Modifica Dati
                </Button>
              </div>
            </div>
          </div>
        )}

        {toggleIsUserCertificateVisible() && (
          <div className="mt-8">
            <p>
              Inserisci i tuoi <strong>dati per il certificato</strong> qui
              sopra per accedere al quiz finale.
            </p>
          </div>
        )}

        {/* QUIZ FINALE */}
        {course?.quiz &&
        isFinalQuizRead() &&
        course?.userCertification.length !== 0 ? (
          <div className="mt-4 mb-8 bg-green-800 rounded-2xl p-4 text-white">
            <p className="uppercase font-bold text-2xl">QUIZ FINALE</p>
            <p>
              hai seguito le lezioni e superato i quiz intermedi, puoi
              effettuare il test finale per ottenere il certificato{" "}
            </p>
            <div className="mt-4">
              <Button onClick={() => toggleModalFinalQuiz(course?.quiz)}>
                Vai al test finale
              </Button>
            </div>
          </div>
        ) : (
          <div className="mt-8 mb-8 bg-orange-800 rounded-2xl p-4 text-white">
            <p className="uppercase font-bold text-2xl">QUIZ FINALE</p>
            <p>
              Potrai accedere al quiz finale solo dopo aver superato{" "}
              {course?.minTime} ore di studio, seguito le lezioni, superato i
              quiz intermedi ed inserito i tuoi dati per il certificato.
            </p>
          </div>
        )}
        {/* END QUIZ FINALE */}

        {/* riscatta certificato */}
        {course?.quiz?.outcome === 1 && (
          <div className="mt-10 rounded-3xl">
            <p className="text-2xl">Riscatta Certificato</p>
            <div className="mt-4 bg-white p-4">
              <p className="font-bold">
                Procedi con il pagamento e riscatta il tuo attestato haccp
              </p>
              <p>
                Puoi pagare con paypal, carta di credito e bonifico bancario
              </p>
              <div className="mt-4">
                <p>Certificato</p>
                <p className="text-xl line-through">
                  <span>
                    {(priceDomain * parseFloat(appConfig?.data[0]?.iva || 0)) /
                      100 +
                      priceDomain}
                  </span>
                  €{" "}
                </p>
                <p className="text-2xl font-bold">
                  <span>
                    {(priceDiscountDomain *
                      parseFloat(appConfig?.data[0]?.iva || 0)) /
                      100 +
                      priceDiscountDomain}
                  </span>
                  €{" "}
                </p>
              </div>
              <div className="mt-4">
                <Button
                  variant="gradient"
                  color="green"
                  onClick={() =>
                    navigate("/payment", {
                      state: {
                        course: course,
                      },
                    })
                  }>
                  Procedi con il pagamento
                </Button>
              </div>
            </div>
          </div>
        )}
      </Layout>

      <Dialog open={isModalLessonOpen} size="xxl">
        <h1 className="text-2xl font-bold text-center text-black p-2">
          {currentLesson.title}
        </h1>
        <div
          className="absolute right-2 top-2"
          onClick={() => toggleModalLesson()}
          // onClick={() => handleConfirmStopLesson()}
        >
          <IconXCircle />
        </div>
        <DialogBody divider>
          {currentLesson?.video ? (
            <VideoPlayer
              src={getCurrentPathLessons()+"/lessons/" +
                course?.code +
                "/videos/" +
                (parseInt(user.idParent) === 0 ? videoPath : videoTutorPath) +
                "/" +
                currentLesson.video +
                ".mp4?" +
                Math.random()
              }
              freeCourse={course?.freeCourse}
              toggleModalLesson={toggleModalLesson}
              handleLessonEnded={handleLessonEnded}
              setIsModalAlertOpen={setIsModalAlertOpen}
            />
          ) : (
            <p>Video al momento non disponibile, ci scusiamo per il disagio</p>
          )}
        </DialogBody>
      </Dialog>

      <Dialog open={isModalAlertOpen} handler={toggleModalAlert} size="xl">
        <div className="py-2 md:py-8 px-2">
          <p className="font-bold md:text-center text-black p-2">
            Stai seguendo la lezione "{currentLesson?.title}" se chiudi perderai
            i tuoi progressi
          </p>
        </div>
        <DialogFooter>
          <Button
            variant="gradient"
            color="red"
            onClick={() => handleCloseAlertModal()}
            className="mr-1">
            <span>Annulla</span>
          </Button>
          <Button
            variant="gradient"
            color="green"
            onClick={() => handleConfirmStopLesson()}
            className="mt-4 md:mt-0">
            <span>Chiudi Lezione</span>
          </Button>
        </DialogFooter>
      </Dialog>

      {/* DIALOG PROVA INTERMEDIA */}
      <Dialog open={isModalQuizOpen} handler={toggleModalQuiz} size="xxl">
        <h1 className="text-2xl font-bold text-black p-2 md:p-4">
          Prova intermedia
        </h1>
        <div className="p-2 md:p-4 overflow-y-scroll">
          <div className="bg-blue-600 text-white p-2 md:p-4">
            <p className="text-xl font-semibold">Modalità di esame</p>
            <p className="mt-4">
              In caso di fallimento, dopo tre tentativi, dovrai ripetere tutte
              le lezioni del modulo. <br />
              Rispondi a tutte le domande per superare la prova intermedia.
            </p>
          </div>
        </div>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={() => toggleModalQuiz()}
            className="mr-1">
            <span>Annulla</span>
          </Button>
          <Button
            variant="gradient"
            color="green"
            onClick={() => {
              window.scrollTo(0, 0);
              navigate("/courses/quiz", {
                state: {
                  course: course,
                  quiz: quiz,
                  registrationCode: registrationCode,
                },
              });
            }}>
            <span>Vai al quiz</span>
          </Button>
        </DialogFooter>
      </Dialog>

      {/* END MODAL QUIZ INTERMEDIO */}

      {/* MODAL QUIZ FINALE */}
      <Dialog
        open={isModalFinalQuizOpen}
        handler={toggleModalFinalQuiz}
        size="xxl">
        <h1 className="text-2xl font-bold text-black p-2 md:p-4">
          Prova Finale
        </h1>

        <div className="p-2 md:p-4 overflow-y-scroll">
          <div className="bg-blue-600 text-white p-2 md:p-4">
            <p className="text-xl font-semibold">Modalità di esame</p>
            <p className="mt-2">
              La prova finale viene considerata superata con massimo quattro
              risposte errate.
            </p>
            <p className="mt-2">Puoi ripetere la prova finale.</p>
          </div>
        </div>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={() => setIsModalFinalQuizOpen(false)}
            className="mr-1">
            <span>Annulla</span>
          </Button>
          <Button
            variant="gradient"
            color="green"
            onClick={() => {
              window.scrollTo(0, 0);
              navigate("/courses/quiz", {
                state: {
                  course: course,
                  quiz: quiz,
                  registrationCode: registrationCode,
                },
              });
            }}>
            <span>Vai al quiz</span>
          </Button>
        </DialogFooter>
      </Dialog>
      {/* END MODAL QUIZ FINALE */}

      {/* CERTIFICATE DIALOG */}
      <CertificateForm
        dialogCertificate={dialogCertificate}
        setDialogCertificate={setDialogCertificate}
        toggleDialogCertificate={toggleDialogCertificate}
        getCourse={getCourse}
        user={user}
      />
      <EditCertificateForm
        dialogEditCertificate={dialogEditCertificate}
        setDialogEditCertificate={setDialogEditCertificate}
        toggleDialogEditCertificate={toggleDialogEditCertificate}
        userCertification={course?.userCertification}
        userPersonalData={course?.userPersonalData}
        registrationCode={registrationCode}
        getCourse={getCourse}
        user={user}
      />
    </>
  );
}
