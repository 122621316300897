import React, { useState } from "react";
import { Input, Button } from "@material-tailwind/react";
import { useForm } from "react-hook-form";
import Logo from "../../assets/logo.svg";
import { certificateService } from "../../api/certificate";
import { pdf } from "@react-pdf/renderer";
import PdfCreate from "../../components/pdfCreate";
import QRcode from "qrcode.react";
import { saveAs } from "file-saver";
import { formatDate } from "../../utils/date";

import styles from "./style.module.scss";

export default function VerifyView() {
  const [/*certificateError*/, setCertificateError] = useState(false);
  const [certificate, setCertificate] = useState(false);
  const [onCheck, setOnCheck] = useState(false);

  const generatePDFDocument = async (item) => {
    const data = {
      item: { ...item },
    };
    let base64Image = document.getElementById("qrcode").toDataURL();
    if (base64Image) {
      const blob = await pdf(
        <PdfCreate qrcode={base64Image} data={data} />
      ).toBlob();
      saveAs(
        blob,
        "certificato-haccp-" + item?.item?.name + "-" + item?.item?.surname
      );
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const params = {
      year: data?.year,
      progress: data?.progress,
      fiscalcode: data?.fiscalCode,
    };

    certificateService
      .verify({ params })
      .then((response) => {
        if (response?.data?.status === 404) {
          setCertificateError(response?.data?.message);
          setOnCheck(true);
        } else if (response?.data?.status === 200) {
          setCertificate(response?.data?.payload[0]);
          setOnCheck(true);
        }
      })
      .catch((error) => {});
  };

  return (
    <div
      className={`${styles.container} flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-gradient-to-b from-blue-800 to-blue-500`}
    >
      <div className="max-w-lg w-full">
        <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8 border-2 rounded-3xl bg-white">
          <div className="w-full max-w-md space-y-8">
            <div>
              <img className="mx-auto h-12 w-auto" src={Logo} alt="LOGO" />
              <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
                Verifica Attestato
              </h2>
            </div>

            {!onCheck ? (
              <form
                className="mt-8 space-y-6"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="-space-y-px rounded-md shadow-sm">
                  <div className="mb-6 flex flex-wrap justify-between items-center gap-2">
                    <div>
                      <Input
                        label="Numero Certificato"
                        type="text"
                        {...register("progress", {
                          required: true,
                          // onChange: (e) => setEmail(e.target.value),
                        })}
                      />
                      {errors.code && (
                        <span className="text-red-900">
                          Inserisci il codice dell'attestato
                        </span>
                      )}
                    </div>
                    <div>
                      <p>/</p>
                    </div>
                    <div>
                      <Input
                        label="Anno"
                        type="year"
                        {...register("year", {
                          required: true,
                          // onChange: (e) => setEmail(e.target.value),
                        })}
                      />
                      {errors.code && (
                        <span className="text-red-900">
                          Inserisci l'anno dell'attestato
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="mb-2">
                    <Input
                      type="text"
                      label="Codice fiscale"
                      {...register("fiscalCode", {
                        required: true,
                        // onChange: (e) => setPassword(e.target.value),
                      })}
                    />
                    {errors.fiscalCode && (
                      <span className="text-red-900">
                        Inserisci il codice fiscale presente nell'attestato
                      </span>
                    )}
                  </div>
                </div>

                <div>
                  <Button
                    type="submit"
                    className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Verifica
                  </Button>
                </div>
              </form>
            ) : (
              <div className="text-center">
                <p>
                  Certificato n.{" "}
                  <span className="font-bold">{certificate?.progress}</span> del{" "}
                  <span className="font-bold">{certificate?.aa}</span>
                </p>
                <p>
                  Sostenuto da <br />{" "}
                  <span className="font-bold">
                    {certificate?.name} {certificate?.surname}
                  </span>
                </p>
                <p>
                  Rilascito in data{" "}
                  <span className="font-bold">
                    {formatDate(certificate?.releasedAt)}
                  </span>
                </p>
                <div className="mt-4 mx-auto">
                  {/* <QRcode
                    value={
                      "https://app.labmar.it/backend/v1/userCertification/verifyCheck/?verify="+certificate.code+""
                    }
                    id="qrcode"
                    className="mx-auto"
                  /> */}

                  {/*<QRcode
                    value={
                      "https://app.labmar.it/verifyQrCode?verify=" +
                      certificate.code +
                      ""
                    }
                    id="qrcode"
                    className="mx-auto"
                  />*/}
                </div>
                {/*<div className="mt-4">
                  <Button onClick={() => generatePDFDocument(certificate)}>
                    Scarica certificato
                  </Button>
                </div>*/}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
